<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Имя_получателя")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div class="text-field-with-icon">
                                        <v-text-field
                                            v-model="recipientName"
                                            hide-details
                                            required
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                    color="cyan" 
                    text 
                    depressed 
                    @click="save" 
                    v-if="recipientName">
                        {{$t("Добавить")}}
                    </v-btn>

                    <v-btn 
                    color="blue-grey" 
                    text 
                    depressed 
                    @click="cancel">
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: "CreateCustomRecipient",
    data () {
        return {
            title: i18n.t("Добавить_получателя"),
            visible: false,
            resolve: null,
            reject: null,

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            recipientName: null
        }
    },
    methods: {
        async open(options = {})
        {
            this.visible = true;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.recipientName = null;
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.resolve({ ContractorType: 2, Id: "00000000-0000-0000-0000-000000000000", IsEmployee: false, Name: this.recipientName.trim() });
            this.recipientName = null;
        }
    }
}
</script>